<template>
  <div class="not_found_page page_container">
    <img :src="NotFoundIcon" alt="not found">
    <h1>404</h1>
    <div class="text_section">

      <h5>{{ $t("oopPageNotFound") }}</h5>
     <p>{{ $t("pageNotFound") }}</p>
    </div>
    <ChopbetButton
        buttonClass=""
        variant="Variant"
        @click="goHome"
        size="small"
        ><span style="text-transform: uppercase">{{ $t("goBackHome") }}</span>
      </ChopbetButton>
  </div>
</template>

<script>
import NotFoundIcon from "../../../assets/images/404.png";
import ChopbetButton from "../../components/ui/ChopbetButton.vue";


export default {
  name: "NotFound",
  components: {
    ChopbetButton
  },
  data() {
    return {
      NotFoundIcon
    }
  },
  methods: {
    goHome() {
      this.$router.push('/')
    }
  },
  mounted: function () {
    this.$store.dispatch("setCurrentPage", "NotFound");
  },
};
</script>

<style scoped>
.not_found_page {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  max-width: 500px;
  height: 82vh;
}
.not_found_page img {
  width: 113px;
  height: 113px;
}

.text_section p{
  color: var(--sub-text-color);
}

.text_section{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
</style>
